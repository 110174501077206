import React, { useState, ReactElement, useMemo } from "react";
import styles from "./style.module.scss";
import { getDomainForm } from "src/api";
import { useDomainContext } from "@hooks/useDomainContext";
import Form from "@components/shared/form";
import ProgressBar from "@components/shared/form/progressBar";
import { FormProps } from "@components/shared/form";
import { TForm } from "@page-builder/ts/generated";
import Caret from "@components/shared/combinedForm/icons/caret";

export default function CombinedForm(
    props: {
        formClasses: FormProps["classes"];
        formClassName: string;
        formCategory: TForm["props"]["formCategory"];
    } & TForm["props"] &
        FormProps,
): ReactElement {
    const { form, updateForm, formattedTitle } = useDomainContext();

    const categories = props.formCategory?.categories ?? [];

    const [isFormFetched, updateFormFetched] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [formError, updateFetchFormError] = useState(null);
    const [formLoading, setFormLoading] = useState<string | false>(false);
    const getForm = async (categorySlug: string) => {
        setFormLoading(categorySlug);
        const { data, error } = await getDomainForm({
            categorySlug,
        });
        setFormLoading(false);
        if (error) {
            // @ts-ignore
            updateFetchFormError(error);
        }
        if (data) {
            updateFetchFormError(null);
            updateForm(data);
            updateFormFetched(true); // display form component
            setCurrentStep(1); // move next step
        }
    };

    const dynamicThankYouFields = useMemo(() => {
        const dynamicThankYouFields: string[] = [];
        form?.steps.forEach((step) => {
            step.fields.forEach((field) => {
                dynamicThankYouFields.push(field.codeName);
            });
        });
        return dynamicThankYouFields;
    }, [form]);

    const formContent = useMemo(
        () =>
            isFormFetched ? (
                <React.Fragment key={form?.title}>
                    <Form
                        {...props}
                        classes={props.formClasses}
                        colors={{}}
                        showProgress={true}
                        progressShift={1}
                        backButton={{
                            class: `${styles["back-button"]}`,
                            firstStepAction: () => {
                                setCurrentStep(0);
                            },
                            label: props?.backButtonText,
                        }}
                        preventWindowClose
                        stepsConfig={[
                            {
                                stepOrder: 0,
                                hideTitle: false,
                                hideDescription: false,
                                forceStepTitle: true,
                            },
                        ]}
                        dynamicThankYouFields={dynamicThankYouFields}
                    />
                </React.Fragment>
            ) : null,
        [isFormFetched, form],
    );

    return (
        <div className={props.formClassName}>
            <div className={`${styles.inner}`}>
                <div
                    style={{
                        transform: `translate(${currentStep * -100}%, 0)`,
                    }}
                    className={styles["steps-wrapper"]}
                >
                    <div className={`${styles.stepBlock}`}>
                        {currentStep === 0 && (
                            <>
                                <div>
                                    <h1
                                        className={styles["title"]}
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                formattedTitle ??
                                                props.formCategory
                                                    ?.categoriesStepTitle ??
                                                "",
                                        }}
                                    ></h1>
                                    <p className={styles["description"]}>
                                        {
                                            props.formCategory
                                                ?.categoriesStepDescription
                                        }
                                    </p>
                                </div>
                                {formError && (
                                    <div className={styles.errorMessage}>
                                        Something went wrong
                                    </div>
                                )}

                                <div
                                    className={
                                        props.formCategory
                                            ?.categoryListStyle === "list"
                                            ? styles[
                                                  "categories-container-list"
                                              ]
                                            : styles[
                                                  "categories-container-grid"
                                              ]
                                    }
                                >
                                    {categories?.map((item) => {
                                        return item.slug ? (
                                            <label
                                                key={item.slug}
                                                className={`${
                                                    styles["category-block"]
                                                } ${
                                                    formLoading
                                                        ? styles["loading"]
                                                        : ""
                                                }`}
                                                onClick={() => {
                                                    if (item.slug) {
                                                        void getForm(item.slug);
                                                    }
                                                }}
                                            >
                                                {item.icon && (
                                                    <div
                                                        className={
                                                            styles[
                                                                "category-icon"
                                                            ]
                                                        }
                                                    >
                                                        {formLoading ===
                                                        item.slug ? (
                                                            <div
                                                                className={
                                                                    styles[
                                                                        "loader-container"
                                                                    ]
                                                                }
                                                            >
                                                                <span
                                                                    className={
                                                                        styles[
                                                                            "loader"
                                                                        ]
                                                                    }
                                                                ></span>
                                                            </div>
                                                        ) : (
                                                            <img
                                                                src={item.icon}
                                                                alt=""
                                                            />
                                                        )}
                                                    </div>
                                                )}

                                                <div
                                                    className={
                                                        styles[
                                                            "category-content"
                                                        ]
                                                    }
                                                >
                                                    <span
                                                        className={
                                                            styles[
                                                                "category-title"
                                                            ]
                                                        }
                                                    >
                                                        {item.title}
                                                    </span>
                                                    {item?.description && (
                                                        <div
                                                            className={
                                                                styles[
                                                                    "category-description"
                                                                ]
                                                            }
                                                        >
                                                            {item?.description}
                                                        </div>
                                                    )}
                                                </div>
                                                {
                                                    <div
                                                        className={styles.caret}
                                                    >
                                                        <Caret />
                                                    </div>
                                                }
                                            </label>
                                        ) : (
                                            <></>
                                        );
                                    })}
                                </div>
                                <ProgressBar
                                    className={`${
                                        props.formClasses
                                            ?.formProgressbarClassName ?? ""
                                    } ${styles["custom-progress"]}`}
                                    current={0}
                                    total={8}
                                    color={undefined}
                                />
                            </>
                        )}
                    </div>

                    <div className={`${styles.stepBlock}`}>
                        {currentStep === 1 && formContent}
                    </div>
                </div>
            </div>
        </div>
    );
}
