import React, { ReactElement } from "react";
import Form from "@components/shared/form";
import styles from "./styles.module.scss";
import { TForm } from "@page-builder/ts/generated";
import { finalShowForm, replacePlaceholders } from "@lib/sharedUtils";
import { useDomainContext } from "@hooks/useDomainContext";
import CombinedForm from "./combinedForm";
import { useRouter } from "next/router";
import AffiliateOffersList from "../affiliateOfferList";
import { convertToBreakpointVariables } from "@page-builder/utils";

const classes = {
    stepTitleClassName: styles["step-title"],
    stepDescriptionClassName: styles["step-description"],
    formButtonClassName: styles["form-button"],
    formFieldClassName: styles["form-field"],
    formStepInnerClassName: styles["steps-inner"],
    stepClassName: styles["step"],
    fieldsClassName: {
        radio: styles["radio-field"],
        checkbox: styles["checkbox-field"],
        select: styles["select-field"],
        streetAddress: styles["streetAddress-field"],
        date: styles["date-picker"],
        all: styles["field"],
        range: styles["range-field"],
    },
    fieldsWrapperClassName: styles["fields-wrapper"],
    tcpaClassName: styles["tcpa"],
    labelAsTitleClassName: styles["label-as-step-title"],
    fieldNoteClassName: styles["field-note"],
    fieldErrorMessageClassName: styles["field-error-message"],
    formProgressbarClassName: styles["progress"],
    sellingPointClassName: styles["selling-point"],
    formFieldLabelClassName: styles["field-label"],
    innerThankYouClassName: styles["thankYouStyle"],
};

export default function MainBanner({
    props = {},
}: {
    props?: TForm["props"];
}): ReactElement {
    const router = useRouter();
    const { category, visitDetails, domain, form } = useDomainContext();

    const offersHeadlineTextBlock = domain.textBlocks?.find(
        (i) => i.position === "offers_headline",
    );

    const displayForm = finalShowForm(
        router.query.sl as string,
        domain.showForm,
        domain.defaultOffers,
    );

    if (!displayForm) {
        return (
            <div
                style={{
                    ...convertToBreakpointVariables({
                        buttonBackgroundColor: "#3ea636",
                        buttonHoverBackgroundColor: "#3ea636",
                    }),
                }}
            >
                <AffiliateOffersList
                    props={{
                        title: offersHeadlineTextBlock?.text,
                        maxNumberOfOffers: 1,
                    }}
                    inForm={true}
                />
            </div>
        );
    }

    const { formCategory } = props;
    const dynamicThankYouFields: string[] = [];

    form?.steps.forEach((step) => {
        step.fields.forEach((field) => {
            dynamicThankYouFields.push(field.codeName);
        });
    });

    const isMultipleCategories =
        !formCategory?.auto && (formCategory?.categories?.length || 0) > 1;

    const sellingPoint = props.sellingPoint
        ? replacePlaceholders(props.sellingPoint, {
              category: category?.name,
              region: visitDetails?.region,
              city: visitDetails?.city,
          })
        : undefined;

    return isMultipleCategories ? (
        <div className={`flex ${styles["form-wrapper"]}`}>
            <CombinedForm
                {...props}
                formClasses={{
                    ...classes,
                }}
                colors={{}}
                sellingPoint={sellingPoint}
                formClassName={styles["form"]}
                formCategory={formCategory}
                formTemplateProp={props.formTemplate}
                resetLeadId
            />
        </div>
    ) : (
        <div className={`flex ${styles["form-wrapper"]}`}>
            <Form
                {...props}
                classes={{
                    ...classes,
                    formClassName: styles["form"],
                }}
                dynamicThankYouFields={dynamicThankYouFields}
                backButton={{ class: styles["back-button"] }}
                formTemplateProp={props.formTemplate}
                colors={{}}
                sellingPoint={sellingPoint}
            />
        </div>
    );
}
