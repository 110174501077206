import React, { ReactElement } from "react";

export default function Caret(): ReactElement {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
            <path fill="none" d="M0 0H256V256H0z"></path>
            <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="16"
                d="M96 48L176 128 96 208"
            ></path>
        </svg>
    );
}
